import axios from "axios"
import { apiHeaders, dateStr } from "./utils"
import { useEffect, useState } from "react"
import { QRCodeSVG } from 'qrcode.react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import "./style.css"

export function GasRecharge({setPageTitle}) {
    useEffect(() => {setPageTitle("燃油充值")}, [])

    const [config, setConfig] = useState(null)
    const [txId, setTxId] = useState("")
    const [err, setErr] = useState({succ: false, msg: ""})
    const [cpTips, setCpTips] = useState({show: false, succ: false})

    const getSysConfig = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/config/sys", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setConfig({reChargeAddr: response.data.data.gas_recharge_addr, reChargeAmountMinimum: response.data.data.gas_recharge_minimum})
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getSysConfig()}, [])

    const handleTxIdChange = (event) => {
        setTxId(event.target.value)
    }

    const setAlert = (succ, msg) => {
        setErr({succ: succ, msg: msg})
        window.setTimeout(() => {
            setErr({succ: false, msg: ""})
        }, 2000)
    }

    const copyRechargeAddr = async (e) => {
        try {
            await navigator.clipboard.writeText(config.reChargeAddr)
            setCpTips({show: true, succ: true})
        } catch (err) {
            setCpTips({show: true, succ: false})
            console.error('Failed to copy: ', err)
        }
    }

    function txIdSubmit(event) {
        event.preventDefault()

        if (!/^[A-Za-z0-9]{64}$/.test(txId)) {
            setAlert(false, "请输入合法的交易id")
            return
        }

        axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/user/gas-recharge", JSON.stringify({tx_id: txId}), {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setTxId("")
                    setAlert(true, "提交成功，待链上确认后将为您充值")
                    return
                }

                setAlert(false, response.data.code + ":" + response.data.msg)
                return
            }

            setAlert(false, response.status + ":" + response.statusText)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <div className="mb-3 alert alert-warning" role="alert">仅支持USDT-TRC20充值，请仔细核对充值地址及充值限额，避免误操作！</div>
            {!config && <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>}
            {config && <>
            <div className="row mb-3">
                <QRCodeSVG size="200" value={config.reChargeAddr} />
            </div>
            <div className="mb-3 row">
                <div className="col-3 text-end">充值币种</div>
                <div className="col-9 text-start">USDT</div>
            </div>
            <div className="mb-3 row">
                <div className="col-3 text-end">充值网络</div>
                <div className="col-9 text-start">TRC20</div>
            </div>
            {config.reChargeAmountMinimum > 0 && (
                <div className="mb-3 row">
                    <div className="col-3 text-end">最低限额</div>
                    <div className="col-9 text-start"><span className="text-danger fw-semibold">{config.reChargeAmountMinimum}</span> <span className="text-danger bg-danger-subtle small ps-1 pe-1 pt-1 pb-1">低于此限额的转账将被视为无效充值</span></div>
                </div>
            )}
            <div className="mb-3 row">
                <div className="col-3 text-end">充值地址</div>
                <div className="col-9 text-start">{config.reChargeAddr} <Tippy content={cpTips.succ ? "已复制" : "复制失败，请手动复制"} visible={cpTips.show} onClickOutside={() => setCpTips({...cpTips, show: false})}><i className="icon-copy" onClick={copyRechargeAddr}></i></Tippy></div>
            </div>
            <div className="mb-3 row">
                <label htmlFor="tx_id" className="col-3 col-form-label text-end">交易id</label>
                <div className="col-9">
                    <input type="text" className="form-control" id="tx_id" value={txId} onChange={handleTxIdChange}></input>
                </div>
            </div>
            <div className={`alert ${err.succ ? "alert-success" : "alert-danger"} text-center ${err.msg ? "visible" : "invisible"}`} role="alert">{err.msg}</div>
            <div className="mb-3 row ms-1 me-1"><button type="submit" className="btn btn-primary" onClick={txIdSubmit}>提交</button></div>
            <div className="row text-center mt-4"><a className="link-offset-2 link-underline link-underline-opacity-0" href="/gas/history">充值记录</a></div>
            </>}
        </>
    )
}

function txIdAbbreviate(txId) {
    return txId.substring(0, 9) + "..." + txId.substring(59)
}

function gasHisItemState(state) {
    switch (state) {
        case 1:
            return "成功"
        case 2:
            return "失败"
        default:
            return "待确认"
    }
}

export function GasHistory({setPageTitle}) {
    useEffect(() => {setPageTitle("燃油充值-历史记录")}, [])

    const [loading, setLoading] = useState(true)
    const [hisList, setHisList] = useState([])

    const queryHistory = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/gas-recharge-history", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setLoading(false)
                setHisList(response.data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {queryHistory()}, [])

    return (
        <>
        <div className="mb-3 alert alert-warning" role="alert">仅查询最近三个月内的充值记录</div>
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        {!loading && (
            <table className="table table-striped">
                <thead><tr><th scope="col">交易id</th><th scope="col" className="text-center">提交时间</th><th scope="col" className="text-end">状态</th></tr></thead>
                <tbody>
                    {hisList && hisList.map((item, id) => {
                        return <tr><td title={item.tx_id}>{txIdAbbreviate(item.tx_id)}</td><td className="text-center">{dateStr(item.ctime)}</td><td className={`text-end ${item.state === 1 && "text-success"} ${item.state === 2 && "text-danger"}`}>{gasHisItemState(item.state)}</td></tr>
                    })}
                    {!hisList && <tr><td colSpan="3">查无记录</td></tr>}
                </tbody>
            </table>
        )}
        <div className="row text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={(e) => {e.preventDefault(); window.history.back();}}>返回</a></div>
        </>
    )
}

function gasLedgerType(tp) {
    switch (tp) {
        case 1:
            return "充值"
        case 2:
            return "后台操作"
        case 3:
            return "盈利扣除"
        case 4:
            return "亏损回补"
        default:
            return ""
    }
}

export function GasLedger({setPageTitle}) {
    useEffect(() => {setPageTitle("燃油收支明细")}, [])

    const [loading, setLoading] = useState(true)
    const [itemList, setItemList] = useState([])

    const queryLedger = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/gas-ledger", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setLoading(false)
                setItemList(response.data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {queryLedger()}, [])

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
        <div className="mb-3 alert alert-warning" role="alert">仅查询最近三个月内的明细记录</div>
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        {!loading && (
            <table className="table table-striped">
                <thead><tr><th scope="col">时间</th><th scope="col">类型</th><th scope="col" className="text-center">数量</th><th scope="col" className="text-center">余额</th></tr></thead>
                <tbody>
                    {itemList && itemList.map((item, id) => {
                        return <tr><td>{dateStr(item.ctime)}</td><td>{gasLedgerType(item.tp)}</td><td className="text-center">{item.delta}</td><td className="text-end">{item.balance}</td></tr>
                    })}
                    {(!itemList || itemList.length === 0) && <tr><td colSpan="4">查无记录</td></tr>}
                </tbody>
            </table>
        )}
        <div className="row text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={(e) => {e.preventDefault(); window.history.back();}}>返回</a></div>
        </>
    )
}

export function GasLedgerDetail({setPageTitle}) {
    useEffect(() => {setPageTitle("燃油收支详情")}, [])
}