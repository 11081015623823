import { useEffect, useState, useRef, useContext } from "react";
import { UserContext } from "../App"
import { isMaster } from "./account";

function listenForHomeMenuOutsideClicks(listening, setListening, menuRef, setIsOpen) {
    return () => {
        if (listening) return;
        if (!menuRef.current) return;
        setListening(true);
        [`click`, `touchstart`].forEach((type) => {
            document.addEventListener(`click`, (evt) => {
                const cur = menuRef.current;
                const node = evt.target;
                if (cur === null || cur.contains(node)) return;
                setIsOpen(false);
            });
        });
    }
}

function PageHead({pageTitle}) {
    const { user, } = useContext(UserContext)

    // Hide and show dropdown
    const [isOpen, setIsOpen] = useState(false);
    const toggle = (isOpen) => {
        return setIsOpen(!isOpen);
    }

    // Hide Dropdown on Outside Click
    const menuRef = useRef(null);
    const [listening, setListening] = useState(false);
    useEffect(listenForHomeMenuOutsideClicks(listening, setListening, menuRef, setIsOpen));

    return (
        <nav className="navbar bg-body-tertiary">
            <div className="container-fluid">
                <div className="dropdown" ref={menuRef}>
                    {
                        user && (
                            <>
                            <a className="navbar-brand" onClick={() => {toggle(isOpen)}} data-bs-toggle="dropdown" aria-expanded="false" style={{cursor: "pointer"}}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect y="6" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect y="12" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect x="6" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect x="6" y="6" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect x="6" y="12" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect x="12" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect x="12" y="6" width="4" height="4" rx="1" fill="currentColor"></rect>
                                    <rect x="12" y="12" width="4" height="4" rx="1" fill="currentColor"></rect>
                                </svg>
                            </a>
                            <ul className={`dropdown-menu ${isOpen && "show"}`} role="button">
                                <li><a className="dropdown-item" href="/">首页</a></li>
                                <li><a className="dropdown-item" href="/exauth">交易授权</a></li>
                                <li><a className="dropdown-item" href="/myfo">{isMaster(user.role) ? "我的带单" : "我的跟单"}</a></li>
                                <li><a className="dropdown-item" href="/gas/recharge">燃油充值</a></li>
                                <li><a className="dropdown-item" href="/invite">邀请注册</a></li>
                            </ul>
                            </>
                        )
                    }
                </div>
                <p className="text-center navbar-nav">{pageTitle}</p>
                <div>
                    {
                        user && (
                            <a className="nav-link" href="/ucenter" id="navbarDropdownMenuLink" role="button" aria-expanded="false">
                                <img className="rounded-circle" src={process.env.REACT_APP_STATIC_BASE_URL + '/avatar/' + user.avatar} width="30" height="30" alt={user.nickname} />
                            </a>
                        )
                    }
                </div>
            </div>
        </nav>
    );
}

export default PageHead;