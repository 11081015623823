import { useState, useContext, useEffect } from "react"
import { UserContext } from "../App"
import axios from "axios"
import { PendingOrders } from "./orders"
import { ActPos } from "./pos"
import { Navigate } from "react-router-dom"
import { hasExcAuth, isMaster, setUserStorage } from "./account"
import { apiHeaders, usePollingEffect } from "./utils"
import { UsrUsdt } from "./user"

function MstList({user, setUser}) {
    const [mstList, setMstList] = useState([])
    const getMstList = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/follow/mlist?exc_id=1", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setMstList(response.data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {getMstList()}, [])

    const foSubmit = async (mid) => {
        await axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/follow", JSON.stringify({exc_id: 1, mid: mid}), {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code !== 0) {
                    alert(response.data.code + ":" + response.data.msg)
                    return
                }
                setUser(response.data.data)
                setUserStorage(response.data.data)
            } else {
                alert(response.status + ":" + response.statusText)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const unfoSubmit = async () => {
        await axios.delete(process.env.REACT_APP_SRV_API_BASE_URL + "/follow", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code !== 0) {
                    alert(response.data.code + ":" + response.data.msg)
                    return
                }
                setUser(response.data.data)
                setUserStorage(response.data.data)
            } else {
                alert(response.status + ":" + response.statusText)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
        <div>
        {mstList && mstList.length > 0 && mstList.map((item, id) => {
            return <div className="row" key={item.uid}>
                <div className="col"><img className="rounded-circle" src={process.env.REACT_APP_STATIC_BASE_URL + '/avatar/' + item.avatar} width="35" height="35" alt={item.nickname} /> <span className="align-bottom text-success">{item.nickname}</span></div>
                <div className="col text-center">周胜率: <span className="text-success fs-1 fw-semibold fst-italic">{item.wrate}%</span></div>
                {user.fo_mid !== item.uid && <div className="col text-end"><button type="button" className="btn btn-primary" disabled={user.fo_mid !== 0} onClick={() => {foSubmit(item.uid)}}>立即跟单</button></div>}
                {user.fo_mid === item.uid && <div className="col text-end"><button type="button" className="btn btn-danger" onClick={() => {unfoSubmit()}}>停止跟单</button></div>}
            </div>
        })}
        </div>
        <hr className="simple"></hr>
        </>
    )
}

function TabList({actTab, setActTab, tabCnts}) {
    return (
        <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
                <button className={`nav-link ${actTab === 1 && "active"}`} onClick={() => actTab !== 1 && setActTab(1)}>委托({tabCnts.pOrds})</button>
            </li>
            <li className="nav-item">
                <button className={`nav-link ${actTab === 2 && "active"}`} onClick={() => actTab !== 2 && setActTab(2)}>持仓({tabCnts.aPos})</button>
            </li>
            <li className="nav-item">
                <button className={`nav-link ${actTab === 3 && "active"}`} onClick={() => actTab !== 3 && setActTab(3)}>资产</button>
            </li>
        </ul>
    );
}

function TabContent({actTab, setTabCnts}) {
    const [loading, setLoading] = useState(true)
    const [ordList, setOrdList] = useState([])
    const [posList, setPosList] = useState([])

    usePollingEffect(async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/trade/pords-actpos", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setLoading(false)
                setOrdList(response.data.data.ordList)
                setPosList(response.data.data.posList)
                setTabCnts({pOrds: response.data.data.ordList.length, aPos: response.data.data.posList.length})
            }
        });
    }, [], { interval: 3000 });

    return (
        <>
        {actTab === 1 && <PendingOrders loading={loading} ordList={ordList} />}
        {actTab === 2 && <ActPos loading={loading} posList={posList} />}
        {actTab === 3 && <UsrUsdt />}
        </>
    )
}

function MyFo({setPageTitle}) {
    const { user, setUser } = useContext(UserContext)
    const [actTab, setActTab] = useState(1)
    const [tabCnts, setTabCnts] = useState({pOrds: 0, aPos: 0})

    useEffect(() => {
        if (user && isMaster(user.role)) {
            setPageTitle("我的带单")
        } else {
            setPageTitle("我的跟单")
        }
    }, [])

    if (!user) {
        return <Navigate to="/signin" replace={true} />;
    }

    if (!hasExcAuth(user.exc_auth)) {
        return <div className="mt-3 alert alert-warning" role="alert">您尚未绑定授权，请<a className="link-offset-2 link-underline link-underline-opacity-0" href="/exauth">立即绑定</a></div>;
    }

    return (
        <>
        {!isMaster(user.role) && <MstList user={user} setUser={setUser} />}
        <TabList actTab={actTab} setActTab={setActTab} tabCnts={tabCnts} />
        <TabContent actTab={actTab} setTabCnts={setTabCnts} />
        </>
    );
}

export default MyFo;