import { useEffect, useState, useContext } from "react";
import { dateFmt, apiHeaders } from "./utils";
import axios from "axios"
import { UserContext } from "../App"
import { Navigate } from "react-router-dom"
import { instIdStr, posSideStr, tdModeStr } from "./common"

function ordTypeStr(ordType) {
    switch (ordType) {
        case 1:
            return "市价"
        case 2:
            return "限价"
        default:
            return "NaN"
    }
}

function reduceOnlyStr(reduceOnly) {
    if (reduceOnly) {
        return "平"
    }
    return "开"
}

function pxTypeStr(pxType) {
    switch (pxType) {
        case 1:
            return "最新"
        case 2:
            return "指数"
        case 3:
            return "标记"
        default:
            return "NaN"
    }
}

function stateStr(state) {
    switch (state) {
        case 2:
            return "已撤单"
        case 3:
            return "部分成交"
        case 4:
            return "完全成交"
        case 5:
            return "自动撤单"
        case 100:
            return "已作废"
        default:
            return ""
    }
}

function pendingOrdItem(ord, id) {
    let coStr = "danger";
    if (ord.posSide === 1) {
        coStr = "success";
    }

    return (
        <div key={id} className={(id === 0 ? "mt-3" : "")}>
            <div>
                <div className="row"><div className="col fw-semibold">{instIdStr(ord.instId)}</div></div>
                <div className="small"><span className={`bg-${coStr}-subtle text-${coStr} ps-1 pe-1`}>{ordTypeStr(ord.ordType)}</span> <span className={`bg-${coStr}-subtle text-${coStr} ps-1 pe-1`}>{reduceOnlyStr(ord.reduceOnly)}{posSideStr(ord.posSide)}</span> <span className="bg-body-secondary text-body-tertiary ps-1 pe-1">{tdModeStr(ord.tdMode)} {ord.lever}x</span> <span className="ms-1 text-body-tertiary">{dateFmt(new Date(ord.ctime))}</span></div>
                <div className="small pt-2">
                    <div className="row">
                        <div className="col"><div className="text-body-tertiary">委托总量(张)</div><div>{ord.sz}</div></div>
                        <div className="col"><div className="text-body-tertiary">已成交量(张)</div><div>{ord.accFillSz}</div></div>
                        <div className="col"><div className="text-body-tertiary text-end">委托价格</div><div className="text-end">{ord.px}</div></div>
                    </div>
                    {(ord.tpTriggerPx !== "" || ord.slTriggerPx !== "") && (
                        <div className="row">
                            <div className="col">
                                <div className="text-body-tertiary">止盈触发价</div>
                                {ord.tpTriggerPx !== "" ? <div>{pxTypeStr(ord.tpTriggerPxType)} {ord.tpTriggerPx}</div> : <div>--</div>}
                            </div>
                            <div className="col">
                                <div className="text-body-tertiary">止损触发价</div>
                                {ord.slTriggerPx !== "" ? <div>{pxTypeStr(ord.slTriggerPxType)} {ord.slTriggerPx}</div> : <div>--</div>}
                            </div>
                            <div className="col"></div>
                        </div>
                    )}
                </div>
            </div>
            <hr className="simple"></hr>
        </div>
    );
}

export function PendingOrders({loading, ordList}) {
    return (
        <>
        {ordList && ordList.map((item, id) => {
            return pendingOrdItem(item, id);
        })}

        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        
        <div className="pt-3"><p className="text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="/history/orders">查看历史委托</a></p></div>
        </>
    );
}

function hisOrdItem(ord, id) {
    let coStr = "danger";
    if ((ord.posSide === 1 && !ord.reduceOnly) || (ord.posSide === 2 && ord.reduceOnly)) { //开多 or 平空
        coStr = "success";
    }

    return (
        <div key={id} className={(id === 0 ? "mt-3" : "")}>
            <div>
                <div className="row"><div className="col fw-semibold">{instIdStr(ord.instId)}</div><div className="col text-end small text-body-tertiary">{stateStr(ord.state)}</div></div>
                <div className="small"><span className={`bg-${coStr}-subtle text-${coStr} ps-1 pe-1`}>{ordTypeStr(ord.ordType)}</span> <span className={`bg-${coStr}-subtle text-${coStr} ps-1 pe-1`}>{reduceOnlyStr(ord.reduceOnly)}{posSideStr(ord.posSide)}</span> <span className="bg-body-secondary text-body-tertiary ps-1 pe-1">{tdModeStr(ord.tdMode)} {ord.lever}x</span> <span className="ms-1 text-body-tertiary">{dateFmt(new Date(ord.ctime))}</span></div>
                <div className="small pt-2">
                    <div className="row">
                        <div className="col"><div className="text-body-tertiary">委托总量(张)</div><div>{ord.sz}</div></div>
                        <div className="col"><div className="text-body-tertiary">已成交量(张)</div><div>{ord.accFillSz}</div></div>
                        {ord.state !== 2 && <div className="col"><div className="text-body-tertiary text-end">成交均价</div><div className="text-end">{ord.avgPx}</div></div>}
                        {ord.state === 2 && <div className="col"><div className="text-body-tertiary text-end">委托价格</div><div className="text-end">{ord.px}</div></div>}
                    </div>
                    {(ord.tpTriggerPx !== "" || ord.slTriggerPx !== "") && (
                        <div className="row">
                            <div className="col">
                                <div className="text-body-tertiary">止盈触发价</div>
                                {ord.tpTriggerPx !== "" ? <div>{pxTypeStr(ord.tpTriggerPxType)} {ord.tpTriggerPx}</div> : <div>--</div>}
                            </div>
                            <div className="col">
                                <div className="text-body-tertiary">止损触发价</div>
                                {ord.slTriggerPx !== "" ? <div>{pxTypeStr(ord.slTriggerPxType)} {ord.slTriggerPx}</div> : <div>--</div>}
                            </div>
                            <div className="col"></div>
                        </div>
                    )}
                    {ord.reduceOnly && (
                        <div className="row">
                        <div className="col">
                            <div className="text-body-tertiary">收益(USDT)</div>
                            <div className={ord.pnl.startsWith("-") ? "text-danger" : "text-success"}>{parseFloat(parseFloat(ord.pnl).toFixed(2))}</div>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                    )}
                </div>
            </div>
            <hr className="simple"></hr>
        </div>
    );
}

function HisOrdList() {
    const [loading, setLoading] = useState(true)
    const [ordList, setOrdList] = useState([])

    const getHisOrdList = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/trade/orders-history", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setLoading(false)
                    setOrdList(response.data.data)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getHisOrdList()}, [])

    return (
        <>
        {ordList && ordList.map((item, id) => {
            return hisOrdItem(item, id);
        })}

        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        
        <div className="pt-3"><p className="text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={(e) => {e.preventDefault(); window.history.back();}}>返回</a></p></div>
        </>
    );
}

export function HistoryOrders({setPageTitle}) {
    useEffect(() => {setPageTitle("历史委托")}, [])
    const { user, setUser } = useContext(UserContext)

    if (!user) {
        return <Navigate to="/signin" replace={true} />;
    }
    
    return HisOrdList()
}