import axios from "axios";
import { UserContext } from "../App"
import { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { apiHeaders } from "./utils"
import { setUserStorage, hasOkxAuth } from "./account"

function ExcAuth({setPageTitle}) {
    useEffect(() => {setPageTitle("交易授权")}, [])

    const [actExc, setActExc] = useState(global.const.exc.okx)
    const [inputs, setInputs] = useState({exc_id: global.const.exc.okx, key: "", secret: "", passwd: ""})
    const [state, setState] = useState({loading: false, errMsg: ""})
    const [reBind, setReBind] = useState(false)
    const { user, setUser } = useContext(UserContext)

    const handleInputsChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value})
    }

    const setAlert = (errMsg) => {
        setState({...state, errMsg: errMsg});
        window.setTimeout(() => {
            setState({...state, errMsg: ""});
        }, 2000);
    }

    const reBindClick = (event) => {
        event.preventDefault()
        setReBind(true)
    }

    function excAuthSubmit(event) {
        event.preventDefault();
        setInputs({...inputs, exc_id: actExc})

        if (inputs.key === "") {
            setAlert("请输入API Key");
            return;
        }

        if (inputs.secret === "") {
            setAlert(inputs.exc_id === global.const.exc.okx ? "请输入密钥" : "请输入Secret Key");
            return;
        }

        if (inputs.exc_id === global.const.exc.okx && inputs.passphrase === "") {
            setAlert("请输入密码");
            return;
        }

        setState({...state, loading: true});
        axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/user/exc-auth", JSON.stringify(inputs), {
            headers: apiHeaders()
        }).then((response) => {
            setState({...state, loading: false});

            if (response.status === 200) {
                if (response.data.code !== 0) {
                    setAlert(response.data.code + ":" + response.data.msg);
                    return;
                }
                
                if (response.data.data) {
                    setUser(response.data.data)
                    setUserStorage(response.data.data)
                }

                setInputs({...inputs, key: "", secret: "", passwd: ""})
                setReBind(false)
                return
            }

            setAlert(response.status + ":" + response.statusText);
        });
    }

    if (!user) {
        return <Navigate to="/signin" replace={true} />;
    }

    return (
        <>
            <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                    <button className={`nav-link ${actExc === global.const.exc.okx && "active"}`} onClick={() => actExc !== global.const.exc.okx && setActExc(global.const.exc.okx)}>欧易</button>
                </li>
                <li className="nav-item">
                    <button className={`nav-link ${actExc === global.const.exc.binance && "active"}`} onClick={() => actExc !== global.const.exc.binance && setActExc(global.const.exc.binance)}>币安</button>
                </li>
            </ul>
            {actExc === global.const.exc.okx && (reBind || !hasOkxAuth(user.exc_auth)) && 
                <form className="row g-3 mt-3" onSubmit={excAuthSubmit}>
                    <div className="mb-3 alert alert-warning" role="alert">创建API时需勾选交易权限，同时为了您的API安全，请绑定IP：{process.env.REACT_APP_SERVER_IP}</div>
                    <div className="mb-3">
                        <label htmlFor="key" className="form-label">API Key</label>
                        <input type="text" className="form-control" id="key" name="key" value={inputs.key} onChange={handleInputsChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="secret" className="form-label">密钥(Secret Key)</label>
                        <input type="text" className="form-control" id="secret" name="secret" value={inputs.secret} onChange={handleInputsChange}  />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="passwd" className="form-label">密码(Passphrase)</label>
                        <input type="text" className="form-control" id="passwd" name="passwd" value={inputs.passwd} onChange={handleInputsChange}  />
                    </div>
                    <div className={`alert alert-danger text-center ${state.errMsg ? "visible" : "invisible"}`} role="alert">{state.errMsg}</div>
                    <button type="submit" className="btn btn-primary" disabled={state.loading}>{state.loading ? <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> : "提交"}</button>
                </form>
            }
            {actExc === global.const.exc.okx && hasOkxAuth(user.exc_auth) && !reBind && <div className="mt-3 alert alert-warning" role="alert">您已绑定授权，可以<a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={reBindClick}>重新绑定</a>，但重新绑定会导致您的当前挂单及持仓失效！</div>}
            {actExc === global.const.exc.binance && <div className="mb-3 alert alert-warning mt-4" role="alert">开发中，敬请期待 ...</div>}
        </>
    );
}

export default ExcAuth;