import { useEffect, useState, useContext } from "react";
import { dateStr, apiHeaders } from "./utils";
import axios from "axios"
import { UserContext } from "../App"
import { Navigate } from "react-router-dom"
import { instIdStr, posSideStr, tdModeStr } from "./common"

function actPosItem(pos, id) {
    let coStr = "success";
    if (pos.posSide === "short") {
        coStr = "danger";
    }

    let upl = parseFloat(pos.upl)
    let uplRatio = parseFloat((parseFloat(pos.uplRatio) * 100).toFixed(2))

    let uplStr = parseFloat(upl.toFixed(2))
    if (upl > 0) {
        uplStr = "+" + uplStr
        uplRatio = "+" + uplRatio
    }

    let margin = pos.mgnMode === "cross" ? pos.imr : pos.margin
    let marginRatio = parseFloat(pos.mgnRatio) * 100

    return (
        <div key={id} className={(id === 0 ? "mt-3" : "")}>
            <div>
                <div className="fw-semibold">{instIdStr(pos.instId)}</div>
                <div className="small"><span className={`bg-${coStr}-subtle text-${coStr} ps-1 pe-1`}>{posSideStr(pos.posSide)}</span> <span className="bg-body-secondary text-body-tertiary ps-1 pe-1">{tdModeStr(pos.mgnMode)}</span> <span className="bg-body-secondary text-body-tertiary ps-1 pe-1">{pos.lever}x</span></div>
                <div className="small pt-2">
                    <div className="row">
                        <div className="col"><div className="text-body-tertiary">收益(USDT)</div><div className={`fw-semibold ${(upl > 0 && "text-success") || (upl < 0 && "text-danger")}`}>{uplStr}</div></div>
                        <div className="col"><div className="text-body-tertiary text-end">收益率</div><div className={`fw-semibold text-end ${(upl > 0 && "text-success") || (upl < 0 && "text-danger")}`}>{uplRatio}%</div></div>
                    </div>
                    <div className="row">
                        <div className="col"><div className="text-body-tertiary">持仓量(张)</div><div>{parseFloat(pos.pos)}</div></div>
                        <div className="col"><div className="text-body-tertiary">保证金(USDT)</div><div>{Math.floor(parseFloat(margin) * 100) / 100}</div></div>
                        <div className="col"><div className="text-body-tertiary text-end">保证金率</div><div className="text-end">{parseFloat(marginRatio.toFixed(2))}%</div></div>
                    </div>
                    <div className="row">
                        <div className="col"><div className="text-body-tertiary">开仓均价</div><div>{pos.avgPx}</div></div>
                        <div className="col"><div className="text-body-tertiary">标记价格</div><div>{pos.markPx}</div></div>
                        <div className="col"><div className="text-body-tertiary text-end">预估强平价</div><div className="text-end">{pos.liqPx}</div></div>
                    </div>
                </div>
            </div>
            <hr className="simple"></hr>
        </div>
    );
}

function posTypeStr(pt) {
    switch (pt) {
        case 1:
            return "部分平仓"
        case 2:
            return "完全平仓"
        case 3:
            return "强平"
        case 4:
            return "强减"
        case 5:
            return "ADL自动减仓"
        default:
            return ""
    }
}

export function ActPos({loading, posList}) {
    return (
        <>
        {posList && posList.map((item, id) => {
            return actPosItem(item, id);
        })}

        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        
        <div className="pt-3"><p className="text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="/history/posList">查看历史持仓</a></p></div>
        </>
    );
}

function hisPosItem(pos, id) {
    let coStr = "success";
    if (pos.direction === 2) {
        coStr = "danger";
    }

    return (
        <>
            <div key={id} className={(id === 0 ? "mt-3" : "")}>
                <div>
                    <div className="row"><div className="col fw-semibold">{instIdStr(pos.instId)}</div><div className="col small text-body-tertiary text-end">{posTypeStr(pos.type)}</div></div>
                    <div className="small"><span className={`bg-${coStr}-subtle text-${coStr} ps-1 pe-1`}>{posSideStr(pos.direction)}</span> <span className="bg-body-secondary text-body-tertiary ps-1 pe-1">{tdModeStr(pos.mgnMode)}</span> <span className="bg-body-secondary text-body-tertiary ps-1 pe-1">{pos.lever}x</span></div>
                    <div className="small pt-2">
                        <div className="row">
                            <div className="col"><div className="text-body-tertiary">开仓均价</div><div>{pos.openAvgPx}</div></div>
                            <div className="col"><div className="text-body-tertiary">已实现收益(USDT)</div><div className={`${(pos.realizedPnl > 0 && "text-success") || (pos.realizedPnl < 0 && "text-danger")}`}>{parseFloat(pos.realizedPnl.toFixed(2))}</div></div>
                            <div className="col"><div className="text-body-tertiary text-end">最大持仓量(张)</div><div className="text-end">{pos.openMaxPos}</div></div>
                        </div>
                        <div className="row">
                            <div className="col"><div className="text-body-tertiary">平仓均价</div><div>{pos.closeAvgPx}</div></div>
                            <div className="col"><div className="text-body-tertiary">已实现收益率</div><div className={`${(pos.realizedPnl > 0 && "text-success") || (pos.realizedPnl < 0 && "text-danger")}`}>{parseFloat((pos.pnlRatio * 100).toFixed(2))}%</div></div>
                            <div className="col"><div className="text-body-tertiary text-end">平仓量(张)</div><div className="text-end">{pos.closeTotalPos}</div></div>
                        </div>
                        <div className="row">
                            <div className="col text-body-tertiary">燃油费</div><div className="col text-end text-danger">{pos.gas}</div>
                        </div>
                        <div className="row">
                            <div className="col text-body-tertiary">开仓时间</div><div className="col text-end">{dateStr(pos.ctime)}</div>
                        </div>
                        <div className="row">
                            <div className="col text-body-tertiary">平仓时间</div><div className="col text-end">{dateStr(pos.utime)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="simple"></hr>
        </>
    )
}

function HisPosList() {
    const [loading, setLoading] = useState(true)
    const [posList, setPosList] = useState([])

    const getHisPosList = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/positions-history", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setLoading(false)
                    setPosList(response.data.data.list)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getHisPosList()}, [])
    
    return (
        <>
        {posList && posList.map((item, id) => {
            return hisPosItem(item, id);
        })}

        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        
        <div className="pt-3"><p className="text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={(e) => {e.preventDefault(); window.history.back();}}>返回</a></p></div>
        </>
    );
}

export function HistoryPositions({setPageTitle}) {
    useEffect(() => {setPageTitle("历史持仓")}, [])
    const { user, setUser } = useContext(UserContext)

    if (!user) {
        return <Navigate to="/signin" replace={true} />;
    }
    
    return HisPosList()
}