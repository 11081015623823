import { Routes, Route } from "react-router-dom"
import React from "react"
import { useState } from "react"
import { Home } from './components/Home'
import ExcAuth from "./components/ExcAuth"
import MyFo from "./components/MyFo"
import { UCenter, Invite } from "./components/user"
import { GasRecharge, GasHistory, GasLedger, GasLedgerDetail } from "./components/gas"
import PageHead from "./components/PageHead"
import { Signin, Signup, RenewPasswd} from './components/account'
import { UsdtLedger, UsdtWithDraw, RewardWithDrawHistory } from "./components/usdt"
import { HistoryOrders } from "./components/orders"
import { HistoryPositions } from "./components/pos"

export const UserContext = React.createContext(null)

function App() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('uinfo')))
  const [pageTitle, setPageTitle] = useState("")

  return (
    <div className="App justify-content-center align-items-center">
    <UserContext.Provider value={{ user: user, setUser: setUser }}>
      <PageHead pageTitle={pageTitle} />
      <div className="container mt-3">
          <Routes>
            <Route path="/" element={<Home setPageTitle={setPageTitle} />}></Route>
            <Route path="/ucenter" element={<UCenter setPageTitle={setPageTitle} />}></Route>
            <Route path="/signin" element={<Signin setPageTitle={setPageTitle} />}></Route>
            <Route path="/signup" element={<Signup setPageTitle={setPageTitle} />}></Route>
            <Route path="/exauth" element={<ExcAuth setPageTitle={setPageTitle} />}></Route>
            <Route path="/myfo" element={<MyFo setPageTitle={setPageTitle} />}></Route>
            <Route path="/renew-passwd" element={<RenewPasswd setPageTitle={setPageTitle} />}></Route>
            <Route path="/gas/recharge" element={<GasRecharge setPageTitle={setPageTitle} />}></Route>
            <Route path="/gas/history" element={<GasHistory setPageTitle={setPageTitle} />}></Route>
            <Route path="/gas/ledger" element={<GasLedger setPageTitle={setPageTitle} />}></Route>
            <Route path="/gas/ledger/:id" element={<GasLedgerDetail setPageTitle={setPageTitle} />}></Route>
            <Route path="/reward/ledger" element={<UsdtLedger setPageTitle={setPageTitle} />}></Route>
            <Route path="/reward/withdraw" element={<UsdtWithDraw setPageTitle={setPageTitle} />}></Route>
            <Route path="/reward/withdraw-history" element={<RewardWithDrawHistory setPageTitle={setPageTitle} />}></Route>
            <Route path="/invite" element={<Invite setPageTitle={setPageTitle} />}></Route>

            <Route path="/history/orders" element={<HistoryOrders setPageTitle={setPageTitle} />}></Route>
            <Route path="/history/posList" element={<HistoryPositions setPageTitle={setPageTitle} />}></Route>
          </Routes>
      </div>
      </UserContext.Provider>
    </div>
  );
}

export default App;
