import axios from "axios"
import { apiHeaders, dateStr, shortDateStr } from "./utils"
import { useEffect, useState } from "react"

function usdtLedgerType(t) {
    switch (t) {
        case 0:
            return "提现"
        case 1:
            return "直推奖"
        case 2:
            return "间推奖"
        case 3:
            return "合伙人管理奖"
        case 4:
            return "合伙人平级奖"
        case 5:
            return "总代管理奖"
        case 6:
            return "总代平级奖"
        case 7:
            return "技术抽成"
        default:
            return "NaN"
    }
}

function ledgerItem(item, id) {
    return (
        <tr><td>{usdtLedgerType(item.type)}</td><td className="text-center">{item.suser && <div><img className="rounded-circle" src={process.env.REACT_APP_STATIC_BASE_URL + '/avatar/' + item.suser.avatar} width="20" height="20" alt={item.suser.nickname} /> {item.suser.nickname}</div>}</td><td className="text-center">{item.amount}</td><td className="text-center">{item.balance}</td><td className="text-end">{dateStr(item.ctime)}</td></tr>
    )
}

export function UsdtLedger({setPageTitle}) {
    useEffect(() => {setPageTitle("钱包明细")}, [])

    const [loading, setLoading] = useState(true)
    const [itemList, setItemList] = useState([])

    const queryLedger = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/usdt-ledger", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setLoading(false)
                setItemList(response.data.data)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {queryLedger()}, [])

    if (loading) {
        return (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <>
        <div className="mb-3 alert alert-warning" role="alert">仅查询最近三个月内的明细记录</div>
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        {!loading && (
            <table className="table table-striped">
                <thead><tr><th scope="col">类型</th><th scope="col" className="text-center">来源</th><th scope="col" className="text-center">数量</th><th scope="col" className="text-center">余额</th><th scope="col" className="text-end">时间</th></tr></thead>
                <tbody>
                    {itemList && itemList.map((item, id) => {
                        return ledgerItem(item, id)
                    })}
                    {(!itemList || itemList.length === 0) && <tr><td colSpan="5">查无记录</td></tr>}
                </tbody>
            </table>
        )}
        <div className="row text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={(e) => {e.preventDefault(); window.history.back();}}>返回</a></div>
        </>
    )
}

export function UsdtWithDraw({setPageTitle}) {
    useEffect(() => {setPageTitle("申请提现")}, []);

    const [inputs, setInputs] = useState({addr: "", amount: ""})
    const [initData, setInitData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [amountTips, setAmountTips] = useState("");
    const [submitSucc, setSubmitSucc] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [validAmount, setValidAmount] = useState(false);

    const handleInputsChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value});

        if (event.target.name === "amount") {
            setValidAmount(false);

            const chkAmount = parseFloat(event.target.value);
            if (isNaN(chkAmount)) {
                setAmountTips("提现额无效");
                return;
            }
            const balAmount = parseFloat(initData.balance);
            if (chkAmount > balAmount) {
                setAmountTips("提现额无效");
            } else if (chkAmount < initData.fee_amount) {
                setAmountTips("提现额低于手续费");
            } else if (initData.fee_amount > 0) {
                setValidAmount(true);
                setAmountTips("实际到账" + (chkAmount - initData.fee_amount));
            }
        }
    }

    const getInitData = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/reward-withdraw", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setLoading(false);
                setInitData(response.data.data);
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getInitData()}, []);

    const setAlert = (errMsg) => {
        setErrMsg(errMsg);
        window.setTimeout(() => {
            setErrMsg("");
        }, 2000)
    }

    const withdrawSubmit = async (event) => {
        setSubmitSucc(false);
        event.preventDefault();

        if (!validAmount) {
            setAlert("请输入有效的提现金额");
            return;
        }

        if (inputs.addr.length !== 34 || !inputs.addr.startsWith("T")) {
            setAlert("请输入有效的提现地址");
            return;
        }

        if (initData.has_unfinished_withdraw) {
            setAlert("您有一笔提现中的申请，请在该申请完成后再提交！");
            return;
        }

        await axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/user/reward-withdraw", JSON.stringify(inputs), {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setSubmitSucc(true);
                    setInitData({...initData, has_unfinished_withdraw: true});
                    setAlert("申请成功，您可以在历史申请中查询提现进度！");
                    return;
                }
                setAlert(response.data.code + ":" + response.data.msg)
                return
            }

            setAlert(response.status + ":" + response.statusText)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
        <div className="mb-3 alert alert-warning" role="alert">仅支持USDT-TRC20提现，请仔细核对提现地址及提现金额，避免误操作！</div>
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        {!loading && (
            <>
            <form onSubmit={withdrawSubmit}>
                <div className="row mb-3">
                    <div className="col-auto">当前余额</div>
                    <div className="col">{initData.balance}</div>
                </div>
                {initData.fee_amount > 0 && <div className="row mb-3">
                    <div className="col-auto">提现手续费</div>
                    <div className="col">{initData.fee_amount}</div>
                </div>}
                <div className="row mb-3 align-items-center">
                    <div className="col-auto">
                        <label htmlFor="amount" className="col-form-label">提现金额</label>
                    </div>
                    <div className="col-3">
                        <input type="text" id="amount" name="amount" className="form-control" onChange={handleInputsChange} />
                    </div>
                    <div className="col-auto">{amountTips !== "" && <span className="text-danger bg-danger-subtle small ps-1 pe-1 pt-1 pb-1">{amountTips}</span>}</div>
                </div>
                <div className="row mb-3">
                    <div className="col-auto"><label htmlFor="addr" className="col-form-label">提现地址</label></div>
                    <div className="col"><input type="text" className="form-control" id="addr" name="addr" onChange={handleInputsChange} /></div>
                </div>
                <div className={`alert ${submitSucc ? "alert-success" : "alert-danger"} text-center ${errMsg === "" ? "invisible" : "visible"}`} role="alert">{errMsg}</div>
                <div className="row"><button type="submit" className="btn btn-primary" disabled={false}>提交</button></div>
            </form>
            <div className="row text-center mt-4"><a className="link-offset-2 link-underline link-underline-opacity-0" href="/reward/withdraw-history">提现记录</a></div>
            </>
        )}
        </>
    );
}

function rewardStateStr(state) {
    switch (state) {
        case 0:
            return "待审核";
        case 1:
            return "提现中";
        case 2:
            return "已完成";
        case 3:
            return "失败";
        case 4:
            return "已驳回";
        default:
            return "NaN";
    }
}

function addrAbbreviate(addr) {
    return addr.substring(0, 8) + "..." + addr.substring(29)
}


export function RewardWithDrawHistory({setPageTitle}) {
    useEffect(() => {setPageTitle("提现记录")}, []);

    const [loading, setLoading] = useState(true);
    const [hisList, setHisList] = useState([]);

    const queryHistory = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/reward-withdraw-history", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setLoading(false);
                setHisList(response.data.data);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {queryHistory()}, [])

    return (
        <>
        <div className="mb-3 alert alert-warning" role="alert">仅查询最近三个月内的提现记录</div>
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        {!loading && (
            <table className="table table-striped">
                <thead><tr><th scope="col">提现地址</th><th scope="col" className="text-center">金额</th><th scope="col" className="text-center">状态</th><th scope="col" className="text-end">申请时间</th></tr></thead>
                <tbody>
                    {hisList && hisList.map((item, id) => {
                        return <tr key={item.id}><td>{addrAbbreviate(item.addr)}</td><td className="text-center">{item.amount}</td><td className="text-center">{rewardStateStr(item.state)}</td><td className="text-end">{shortDateStr(item.ctime)}</td></tr>
                    })}
                    {(!hisList || hisList.length === 0) && <tr><td colSpan="4">查无记录</td></tr>}
                </tbody>
            </table>
        )}
        <div className="row text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={(e) => {e.preventDefault(); window.history.back();}}>返回</a></div>
        </>
    )
}