import { useState } from "react";
import axios from "axios";
import { usePollingEffect } from "./utils";

function OkxPrice({title, instId}) {
    const [price, setPrice] = useState({curr: "", upDown: 0, percent: 0});

    usePollingEffect(async () => {
        await axios.get(process.env.REACT_APP_OKX_REST_BASE_URL + "/api/v5/market/ticker?instId=" + instId).then((response) => {
            if (response.status === 200 && response.data.code === "0") {
                const ret = response.data.data[0];
                const open = parseFloat(ret.sodUtc0);
                const last = parseFloat(ret.last);
                const dVal = last - open;
                const percent = Math.abs(dVal / open * 100).toFixed(2);

                setPrice({curr: ret.last, upDown: dVal, percent: percent});
            }
        });
    }, [], { interval: 3000 });

    return (
        <div className={`col text-center ${price.upDown < 0 && "text-danger bg-danger-subtle"} ${price.upDown > 0 && "text-success bg-success-subtle"}`}>
            <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{price.curr}<br />({(price.upDown > 0 && "+") || (price.upDown < 0 && "-")}{price.percent}%)</p>
            </div>
        </div>
    );
}

export default OkxPrice;