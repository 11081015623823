import { useEffect, useRef } from "react";
import { userInfo, usrToken } from "./account"

export const apiHeaders = () => {
    let ret = {
        'content-type': 'application/json',
        'X-Platform': '1'
    };

    const uinfo = userInfo();
    if (uinfo) {
        ret['X-Uid'] = uinfo.uid;
        ret['X-Token'] = usrToken();
    }

    return ret;
}

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
    }, [callback, delay]);
}

export const dateFmt = (date) => {
    let fz = (v) => {
        if (v < 10) {
            return '0' + v;
        }
        return v;
    }
    
    return fz(date.getMonth() + 1) + "/" + fz(date.getDate()) + " " + fz(date.getHours()) + ":" + fz(date.getMinutes()) + ":" + fz(date.getSeconds());
}

export const dateStr = (timeStamp) => {
    const t = new Date(timeStamp)

    const fz = (v) => {
        if (v < 10) {
            return '0' + v;
        }
        return v;
    }

    return t.getFullYear() + "-" + fz(t.getMonth() + 1) + "-" + fz(t.getDate()) + " " + fz(t.getHours()) + ":" + fz(t.getMinutes()) + ":" + fz(t.getSeconds());
}

export const shortDateStr = (timeStamp) => {
    const t = new Date(timeStamp)

    const fz = (v) => {
        if (v < 10) {
            return '0' + v;
        }
        return v;
    }

    return fz(t.getMonth() + 1) + "/" + fz(t.getDate()) + " " + fz(t.getHours()) + ":" + fz(t.getMinutes());
}

export function usePollingEffect(
    asyncCallback,
    dependencies = [],
    { 
        interval = 10_000, // 10 seconds,
        onCleanUp = () => {}
    } = {},) {
    const timeoutIdRef = useRef(null)
    useEffect(() => {
        let _stopped = false
        // Side note: preceding semicolon needed for IIFEs.
        ;(async function pollingCallback() {
            try {
                await asyncCallback()
            } finally {
                // Set timeout after it finished, unless stopped
                timeoutIdRef.current = !_stopped && setTimeout(
                    pollingCallback,
                    interval
                )
            }
        })()
        // Clean up if dependencies change
        return () => {
            _stopped = true // prevent racing conditions
            clearTimeout(timeoutIdRef.current)
            onCleanUp()
        }
    }, [...dependencies, interval])
}