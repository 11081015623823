import { useContext, useEffect, useState } from "react"
import axios from "axios"
import OkxPrice from "./OkxPrice"
import { UserContext } from "../App"
import { Navigate } from "react-router-dom"
import { apiHeaders, usePollingEffect } from "./utils"

function UsrFoOverview() {
    const [overview, setOverview] = useState(null)

    usePollingEffect(async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/fo-overview", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setOverview(response.data.data)
            }
        });
    }, [], { interval: 5000 });

    const textClass = (v) => {
        if (v === 0) {
            return ""
        } else if (v > 0) {
            return "text-success"
        } else {
            return "text-danger"
        }
    }

    return <div className="card mb-3">
        <div className="card-header">跟单概况</div>
        <div className="card-body">
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col text-end">总收益</div>
                        <div className={`col text-start ${textClass(overview && overview.gain ? overview.gain.total : 0)}`}>{overview && overview.gain ? parseFloat(overview.gain.total.toFixed(2)) : 0}</div>
                    </div>
                    <div className="row">
                        <div className="col text-end">今日盈利</div>
                        <div className={`col text-start ${textClass(overview && overview.gain ? overview.gain.today : 0)}`}>{overview && overview.gain ? parseFloat(overview.gain.today.toFixed(2)) : 0}</div>
                    </div>
                    <div className="row">
                        <div className="col text-end">燃油余额</div>
                        <div className={`col text-start ${textClass(overview ? parseFloat(overview.gas) : 0)}`}>{overview ? parseFloat(parseFloat(overview.gas).toFixed(2)) : 0}</div>
                    </div>
                    {overview && overview.okxUsdt && <div className="row">
                        <div className="col text-end">欧易余额</div>
                        <div className="col text-start">{overview && overview.gain ? parseFloat(parseFloat(overview.okxUsdt.cashBal).toFixed(2)) : 0}</div>
                    </div>}
                </div>
                <div className="col text-center">
                    <div className={`fs-2 fw-semibold fst-italic ${textClass(overview && overview.gain ? overview.gain.pending : 0)}`}>{overview && overview.gain ? parseFloat(overview.gain.pending.toFixed(2)) : 0}</div>
                    <div>当前持仓收益</div>
                </div>
            </div>
        </div>
    </div>
}

function PnlTopList() {
    const [topList, setTopList] = useState(null)

    const getTopList = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/mess/pnl-toplist", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setTopList(response.data.data)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getTopList()}, [])

    if (!topList || topList.length === 0) {
        return
    }

    return (
        <div className="card">
            <div className="card-header">最近7天收益排行榜</div>
            <div className="card-body">
            {topList.map((item, id) => {
                return <div className={`row ${id > 0 && "mt-2"}`} key={id}><div className="col"><img className="rounded-circle" src={process.env.REACT_APP_STATIC_BASE_URL + '/avatar/' + item.avatar} width="30" height="30" alt={item.nickname} /> {item.nickname}</div><div className="col text-end text-success fw-semibold">+{item.total_pnl.toFixed(2)}</div></div>;
            })}
            </div>
        </div>
    )
}

export function Home({setPageTitle}) {
    const { user, } = useContext(UserContext)
    useEffect(() => {setPageTitle("")}, [])

    if (!user) {
        return <Navigate to="/signin" replace={true} />
    }

    return (
        <>
            <UsrFoOverview />
            <div className="card mb-3">
                <div className="card-header">实时行情</div>
                <div className="card-body text-center row">
                    <OkxPrice title="BTCUSDT" instId="BTC-USDT-SWAP" />
                    <OkxPrice title="ETHUSDT" instId="ETH-USDT-SWAP" />
                </div>
            </div>
        </>
    )
}
