function instTypeStr(instType) {
    switch (instType) {
        case "MARGIN":
            return "杠杆"
        case "SWAP":
            return "永续"
        case "FUTURES":
            return "交割"
        case "OPTION":
            return "期权"
        default:
            return ""
    }
}

export function instIdStr(instId) {
    let instArr = instId.split("-");
    let ret = instArr[0] + instArr[1];
    if (instArr.length > 2) {
        ret += instTypeStr(instArr[2])
    }
    return ret
}

export function posSideStr(posSide) {
    if (posSide === 1 || posSide === "long") {
        return "多"
    }
    return "空"
}

export function tdModeStr(tdMode) {
    switch (tdMode) {
        case 1:
        case "cross":
            return "全仓"
        case 2:
        case "isolated":
            return "逐仓"
        case 3:
        case "cash":
            return "现金"
        default:
            return "NaN"
    }
}
