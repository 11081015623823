import { useContext, useEffect, useState } from "react"
import axios from "axios"
import { apiHeaders } from "./utils"
import { UserContext } from "../App"
import { Navigate } from "react-router-dom"
import { cleanUserState } from "./account"
import { QRCodeSVG } from 'qrcode.react'
import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'
import "./style.css"

export function UsrUsdt() {
    const [usdtInfo, setUsdtInfo] = useState(null)
    const [loading, setLoading] = useState(true)

    const getUsrUsdt = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/okx-usdt", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setLoading(false)
                    setUsdtInfo(response.data.data)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getUsrUsdt()}, [])

    return (
        <>
        {usdtInfo && <>
            <div className="row mt-3"><div className="col fw-semibold fs-5"><img src={process.env.REACT_APP_STATIC_BASE_URL + '/img/usdt-logo.png'} width="30" height="30" alt="usdt" /> USDT</div></div>
            <div className="row mt-2">
                <div className="col"><div className="text-body-tertiary">币种权益</div><div>{parseFloat(parseFloat(usdtInfo.eq).toFixed(4))}</div></div>
                <div className="col text-center"><div className="text-body-tertiary">占用</div><div>{parseFloat(parseFloat(usdtInfo.frozenBal).toFixed(4))}</div></div>
                <div className="col text-end"><div className="text-body-tertiary">可用</div><div>{parseFloat(parseFloat(usdtInfo.availBal).toFixed(4))}</div></div>
            </div>
        </>}
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        </>
    )
}

export function UCenter({setPageTitle}) {
    useEffect(() => {setPageTitle("个人中心")}, [])
    const { user, setUser } = useContext(UserContext)
    const [userExtInfo, setUserExtInfo] = useState(null)
    const [errMsg, setErrMsg] = useState("")

    const getUserExtInfo = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/ext-info", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setUserExtInfo(response.data.data)
                } else if (response.data.code === 401) {
                    cleanUserState()
                    setUser(null)
                } else {
                    setErrMsg(response.data.code + ":" + response.data.msg)
                }
            } else {
                setErrMsg("请刷新页面重试")
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getUserExtInfo()}, [])

    const doSignout = (event) => {
        event.preventDefault()
        cleanUserState()
        setUser(null)
    }
    
    if (!user) {
        return <Navigate to="/signin" replace={true} />
    }

    return (
        <>
            <div className="d-flex justify-content-center"><img className="rounded-circle" src={process.env.REACT_APP_STATIC_BASE_URL + '/avatar/' + user.avatar} alt={user.nickname} /></div>

            <div className="row mt-3">
                <div className="col text-end" style={{paddingRight: "10px"}}>uid:</div>
                <div className="col" style={{paddingLeft: 0}}>{user.uid}</div>
            </div>
            <div className="row mt-1">
                <div className="col text-end" style={{paddingRight: "10px"}}>邮箱:</div>
                <div className="col" style={{paddingLeft: 0}}>{user.email}</div>
            </div>
            <div className="row mt-1">
                <div className="col text-end" style={{paddingRight: "10px"}}>昵称:</div>
                <div className="col" style={{paddingLeft: 0}}>{user.nickname}</div>
            </div>
            <div className="row mt-1">
                <div className="col text-end" style={{paddingRight: "10px"}}>交易授权:</div>
                <div className="col" style={{paddingLeft: 0}}>{user.exc_auth === 0 && <span><a className="link-offset-2 link-underline link-underline-opacity-0" href="/exauth">点击授权</a></span>}{(user.exc_auth & global.const.exc.okx) === global.const.exc.okx && "欧易"} {(user.exc_auth & global.const.exc.binance) === global.const.exc.binance && "币安"}</div>
            </div>

            {userExtInfo && <>
                <div className="row mt-1">
                    <div className="col text-end" style={{paddingRight: "10px"}}>账号等级:</div>
                    <div className="col" style={{paddingLeft: 0}}>{userExtInfo.level === 2 ? "总代理" : (userExtInfo.level === 1 ? "合伙人" : "普通账号")}</div>
                </div>
                <div className="row mt-1">
                    <div className="col text-end" style={{paddingRight: "10px"}}>燃油余额:</div>
                    <div className="col" style={{paddingLeft: 0}}>{userExtInfo.gas} <a className="link-offset-2 link-underline link-underline-opacity-0" href="/gas/ledger">明细</a> <a className="link-offset-2 link-underline link-underline-opacity-0" href="/gas/recharge">充值</a></div>
                </div>
                {userExtInfo.usdt > 0 && 
                <div className="row mt-1">
                    <div className="col text-end" style={{paddingRight: "10px"}}>奖励钱包:</div>
                    <div className="col" style={{paddingLeft: 0}}>{userExtInfo.usdt} <a className="link-offset-2 link-underline link-underline-opacity-0" href="/reward/ledger">明细</a> <a className="link-offset-2 link-underline link-underline-opacity-0" href="/reward/withdraw">提现</a></div>
                </div>}
            </>}
            
            <div className={`mt-1 alert alert-danger text-center ${errMsg ? "visible" : "invisible"}`} role="alert">{errMsg}</div>
            <div className="row mt-3">
                <div className="col text-center"><a className="link-offset-2 link-underline link-underline-opacity-0" href="/renew-passwd">修改密码</a></div>
                <div className="col text-center"><a className="link-offset-2 link-underline link-underline-opacity-0" href="#" onClick={doSignout}>退出登录</a></div>
            </div>
        </>
    )
}

export function Invite({setPageTitle}) {
    useEffect(() => {setPageTitle("邀请注册")}, [])

    const [loading, setLoading] = useState(true)
    const [spreadLink, setSpreadLink] = useState("")
    const [cpTips, setCpTips] = useState({show: false, succ: false})

    const getInvCode = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/user/inv-code", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setLoading(false)
                    setSpreadLink(window.location.origin + "/signup?" + response.data.data)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {getInvCode()}, [])

    const copySpreadLinkToClipboard = async (event) => {
        try {
            await navigator.clipboard.writeText(spreadLink)
            setCpTips({show: true, succ: true})
        } catch (err) {
            setCpTips({show: true, succ: false})
            console.error('Failed to copy: ', err)
        }
    }

    return (
        <>
        {!loading && <>
            <div className="row mt-3">
                <QRCodeSVG size="256" value={spreadLink} />
            </div>
            <div className="row mt-3">
                    <div className="col text-center">{spreadLink} {spreadLink && <Tippy content={cpTips.succ ? "已复制" : "复制失败，请手动复制"} visible={cpTips.show} onClickOutside={() => setCpTips({...cpTips, show: false})}><i className="icon-copy" onClick={copySpreadLinkToClipboard}></i></Tippy>}</div>
                </div>
        </>}
        {loading && (
            <div className="d-flex justify-content-center mt-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        )}
        </>
    )
}
