import { useState, useContext, useEffect } from "react"
import axios from "axios"
import { UserContext } from "../App"
import { Navigate } from "react-router-dom"
import { apiHeaders } from "./utils"

export const setUserStorage = (uInfo) => {
    localStorage.setItem("uinfo", JSON.stringify(uInfo))
}

export const setTokenStorage = (token, tokenDeadline) => {
    localStorage.setItem("token", JSON.stringify({token: token, deadline: tokenDeadline}))
}

export const cleanUserState = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("uinfo")
}

export const usrToken = () => {
    const token = localStorage.getItem("token")
    if (!token) {
        return ""
    }

    const tokenInfo = JSON.parse(token)
    if (tokenInfo.deadline > Math.floor(Date.now() / 1000)) {
        return tokenInfo.token
    }

    cleanUserState()
    return ""
}

export const hasLogged = () => {
    return usrToken() !== ""
}

export const userInfo = () => {
    if (!hasLogged()) { return null }
    const uinfo = localStorage.getItem("uinfo");
    if (!uinfo) {
        return null
    }

    return JSON.parse(uinfo)
}

export const hasOkxAuth = (excAuth) => {
    return (excAuth & 1) === 1
}

export const hasBinanceAuth = (excAuth) => {
    return (excAuth & 2) === 2
}

export const hasExcAuth = (excAuth) => {
    return hasOkxAuth(excAuth) || hasBinanceAuth(excAuth)
}

export const isMaster = (role) => {
    return (role & 4096) === 4096
}

export const foMid = () => {
    const uInfo = userInfo();
    if (!uInfo) {
        return 0;
    }

    return uInfo.fo_mid
}

export const chkEmail = (email) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

export const chkUsrPasswd = (passwd) => {
    return passwd.length > 6 && passwd.length < 30
}


export function Signin({setPageTitle}) {
    useEffect(() => {setPageTitle("用户登录")}, [])
    const { user, setUser} = useContext(UserContext)

    const [inputs, setInputs] = useState({
        email: "",
        passwd: ""
    })
    const [state, setState] = useState({loading: false, signinSucc: false, errMsg: ""})

    const handleInputsChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value})
    }

    const setAlert = (errMsg, autoHide) => {
        setState({...state, errMsg: errMsg})
        if (autoHide) {
            window.setTimeout(() => {
                setState({...state, errMsg: ""})
            }, 2000)
        }
    }

    function signinSubmit(event) {
        event.preventDefault()

        //表单校验
        if (!chkEmail(inputs.email)) {
            setAlert("请输入有效的邮箱地址", false)
            return
        }

        if (!chkUsrPasswd(inputs.passwd)) {
            setAlert("请输入有效的登录密码", false)
            return
        }

        setState({...state, loading: true})
        axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/user/signin", JSON.stringify(inputs), {
            headers: apiHeaders()
        }).then((response) => {
            console.log(response)
            setState({...state, loading: false})

            if (response.status === 200) {
                if (response.data.code !== 0) {
                    setAlert(response.data.code + ":" + response.data.msg, true)
                    return
                }
                
                setUser(response.data.data.user)
                setUserStorage(response.data.data.user)
                setTokenStorage(response.data.data.token, response.data.data.token_deadline)
                return
            }

            setAlert(response.status + ":" + response.statusText, true)
        })
    }

    if (user) {
        return <Navigate to="/" replace={true} />
    }

    return (
        <>
            <form className="row g-3" onSubmit={signinSubmit}>
                <div className="mb-3">
                    <label htmlFor="email" className="form-label">邮箱</label>
                    <input type="email" className="form-control" id="email" name="email" value={inputs.email} onChange={handleInputsChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="passwd" className="form-label">密码</label>
                    <input type="password" className="form-control" id="passwd" name="passwd" value={inputs.passwd} onChange={handleInputsChange} />
                </div>
                <div className={`alert alert-danger text-center ${state.errMsg ? "visible" : "invisible"}`} role="alert">{state.errMsg}</div>
                <button type="submit" className="btn btn-primary">登录</button>
            </form>
            <div><p className="text-center mt-5"><a className="link-offset-2 link-underline link-underline-opacity-0" href="/signup">免费注册</a></p></div>
        </>
    )
}

export function Signup({setPageTitle}) {
    useEffect(() => {setPageTitle("账号注册")}, [])

    const { user, } = useContext(UserContext)
    const [inputs, setInputs] = useState({
        email: "",
        nickname: "",
        passwd: "",
        inv_code: "",
        rePasswd: ""
    })

    const [invSigup, setInvSigup] = useState(true) //是否必须邀请注册
    const getSysConfig = async () => {
        await axios.get(process.env.REACT_APP_SRV_API_BASE_URL + "/config/sys", {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200 && response.data.code === 0) {
                setInvSigup(response.data.data.signup_with_invite)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    useEffect(() => {getSysConfig()}, [])

    const [state, setState] = useState({invCodeReadOnly: false, loading: false, regSucc: false, navigate: false, errMsg: ""})


    useEffect(() => {
        const qStr = window.location.search
        if (qStr.startsWith("?")) {
            const invCode = qStr.substring(1)
            if (chkInvCode(invCode)) {
                setInputs({...inputs, inv_code: invCode})
                setState({...state, invCodeReadOnly: true})
            }
        }
    }, [])
    
    const chkInvCode = (invCode) => {
        return /^[A-Za-z0-9]{6}$/.test(invCode);
    }

    const handleInputsChange = (event) => {
        setInputs({...inputs, [event.target.name]: event.target.value})
    }

    const setAlert = (errMsg) => {
        setState({...state, errMsg: errMsg})
        window.setTimeout(() => {
            setState({...state, errMsg: ""})
        }, 2000)
    }

    const regSucc = () => {
        setState({...state, regSucc: true, errMsg: "注册成功，即将为您跳转到登录页"})
        window.setTimeout(() => {
            setState({...state, navigate: true})
        }, 2000)
    }

    const validate = () => {
        if (!chkEmail(inputs.email)) {
            setAlert("请输入有效的邮箱地址")
            return false
        }

        if (inputs.nickname.length < 2 || inputs.nickname.length > 12) {
            setAlert("请输入有效的昵称(长度2-12)")
            return false
        }

        if ((invSigup || inputs.inv_code.length > 0) && !chkInvCode(inputs.inv_code)) {
            setAlert("请输入有效的邀请码")
            return false
        }

        if (!chkUsrPasswd(inputs.passwd)) {
            setAlert("请输入有效的登录密码(长度6-30)")
            return false
        }

        if (inputs.rePasswd === "") {
            setAlert("请确认密码")
            return false
        }

        if (inputs.rePasswd !== inputs.passwd) {
            setAlert("两次输入的密码不一致")
            return false
        }
        return true
    }

    function signupSubmit(event) {
        event.preventDefault()

        //表单校验
        if (!validate()) {
            return
        }

        setState({...state, loading: true})
        axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/user/signup", JSON.stringify(inputs), {
            headers: apiHeaders()
        }).then((response) => {
            console.log(response)
            setState({...state, loading: false})

            if (response.status === 200) {
                if (response.data.code === 0) {
                    regSucc()
                    return
                }
                setAlert(response.data.code + ":" + response.data.msg)
                return
            }

            setAlert(response.status + ":" + response.statusText)
        })
    }

    if (user) {
        return <Navigate to="/" replace={true} />
    }

    if (state.navigate) {
        return <Navigate to="/signin" replace={true} />
    }

    return (
        <>
        <form className="row g-3" onSubmit={signupSubmit}>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">邮箱 <span className="text-danger">*</span></label>
                <input type="email" className="form-control" id="email" name="email" value={inputs.email} onChange={handleInputsChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="nickname" className="form-label">昵称 <span className="text-danger">*</span></label>
                <input type="text" className="form-control" id="nickname" name="nickname" value={inputs.nickname} onChange={handleInputsChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="inv_code" className="form-label">邀请码 {invSigup && <span className="text-danger">*</span>}</label>
                <input type="text" className="form-control" id="inv_code" name="inv_code" value={inputs.inv_code} readOnly={state.invCodeReadOnly} disabled={state.invCodeReadOnly} onChange={handleInputsChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="passwd" className="form-label">登录密码 <span className="text-danger">*</span></label>
                <input type="password" className="form-control" id="passwd" name="passwd" value={inputs.passwd} onChange={handleInputsChange} />
            </div>
            <div className="mb-3">
                <label htmlFor="repasswd" className="form-label">确认密码 <span className="text-danger">*</span></label>
                <input type="password" className="form-control" id="rePasswd" name="rePasswd" value={inputs.rePasswd} onChange={handleInputsChange} />
            </div>
            <div className={`alert ${state.regSucc ? "alert-success" : "alert-danger"} text-center ${state.errMsg ? "visible" : "invisible"}`} role="alert">{state.errMsg}</div>
            <button type="submit" className="btn btn-primary" disabled={state.loading}>{state.loading ? <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> : "注册"}</button>
        </form>
        <div className="row"><p className="text-center mt-5">已有账号，<a className="link-offset-2 link-underline link-underline-opacity-0" href="/signin">立即登录</a></p></div>
        </>
    )
}

export function RenewPasswd({setPageTitle}) {
    useEffect(() => {setPageTitle("修改密码")}, [])
    const { user, setUser } = useContext(UserContext)
    const [inputs, setInputs] = useState({})
    const [errMsg, setErrMsg] = useState({stStr: "danger", msg: ""})

    const setAlert = (msg, stStr = "danger") => {
        setErrMsg({stStr: stStr, msg: msg})
        window.setTimeout(() => {
            setErrMsg({stStr: "danger", msg: ""})
        }, 2000)
    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
        setInputs({...inputs, [name]: value})
    }

    function renewPwdSubmit(event) {
        event.preventDefault()

        if (!inputs.oldpwd || !chkUsrPasswd(inputs.oldpwd)) {
            setAlert("旧密码错误")
            return
        }

        if (!inputs.newpwd || !chkUsrPasswd(inputs.newpwd)) {
            setAlert("请输入有效的新密码(长度6-30)")
            return
        }

        if (!inputs.repwd || inputs.newpwd !== inputs.repwd) {
            setAlert("两次输入的密码不一致")
            return
        }

        axios.post(process.env.REACT_APP_SRV_API_BASE_URL + "/user/renew-passwd", JSON.stringify(inputs), {
            headers: apiHeaders()
        }).then((response) => {
            if (response.status === 200) {
                if (response.data.code === 0) {
                    setAlert("密码修改成功，请重新登录", "success")
                    window.setTimeout(() => {
                        cleanUserState()
                        setUser(null)
                    }, 2000)
                    return
                }
                setAlert(response.data.code + ":" + response.data.msg)
                return
            }

            setAlert(response.status + ":" + response.statusText)
        }).catch(err => {
            console.log(err)
        })
    }

    if (!user) {
        return <Navigate to="/signin" replace={true} />
    }

    return (
        <>
            <form className="row g-3" onSubmit={renewPwdSubmit}>
                <div className="mb-3">
                    <label htmlFor="oldpwd" className="form-label">旧密码</label>
                    <input type="password" className="form-control" id="oldpwd" name="oldpwd" onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="newpwd" className="form-label">新密码</label>
                    <input type="password" className="form-control" id="newpwd" name="newpwd" onChange={handleChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="repwd" className="form-label">确认密码</label>
                    <input type="password" className="form-control" id="repwd" name="repwd" onChange={handleChange} />
                </div>
                <div className={`alert alert-${errMsg.stStr} text-center ${errMsg.msg ? "visible" : "invisible"}`} role="alert">{errMsg.msg}</div>
                <button type="submit" className="btn btn-primary">提交</button>
            </form>
        </>
    )
}
